@font-face {
  font-family: "Darker Grotesque";
  src: url("../fonts/Darker_Grotesque/DarkerGrotesque-VariableFont_wght.woff2")
    format("woff2-variations");
  font-weight: 100 900;
  font-display: swap;
  size-adjust: 100%;
}

@font-face {
  font-family: "Nebulica";
  src: url("../fonts/Nebulica/WOFF2/Nebulica-Regular.woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Nebulica";
  src: url("../fonts/Nebulica/WOFF2/Nebulica-Medium.woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Nebulica";
  src: url("../fonts/Nebulica/WOFF2/Nebulica-SemiBold.woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Nebulica";
  src: url("../fonts/Nebulica/WOFF2/Nebulica-Bold.woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Neuething Sans";
  src: url("../fonts/Neuething_Sans/Web/WOFF2/NeuethingSans-Regular.woff2");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: "Neuething Sans";
  src: url("../fonts/Neuething_Sans/Web/WOFF2/NeuethingSans-Bold.woff2");
  font-display: swap;
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding-top: 80px;
  line-height: 1.6;
  color: #333;
  background: linear-gradient(0deg, rgba(242, 242, 242, 0.4) 0%, rgba(242, 242, 242, 0.4) 100%),
    #f2f2f2;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 40px;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.video-background .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.video-background.image-fallback {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/poster-image.jpg");
}

/* Optional overlay to darken the video */
/* .video-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1; */
/* } */

/* Header */
header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: #00000026;
  height: 80px;
  padding: 20px 0;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.nav-links {
  display: inline-flex;
  align-items: flex-start;
  gap: 35px;
  position: relative;
}

.nav-links a {
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: "Darker Grotesque-Medium", Helvetica;
  font-weight: 500;
  color: #f2f2f2;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  text-decoration: none;
}

/* Hero Section */
.hero {
  position: relative;
  min-height: 80vh;
  /* margin-top: -80px; */
  /* padding: 100px 0; */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.grid-02 {
  left: 0;
  mix-blend-mode: screen;
  position: absolute;
  top: 0;
  width: 100%;
  height: 110vh;
  z-index: 2;
  pointer-events: none;
  object-fit: cover;
}

.scroll-button {
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 3;
}

.hero .container {
  z-index: 3;
  margin-top: auto;
  margin-bottom: auto;
}

.hero h1 .span0 {
  color: #f2f2f2;
  margin-bottom: 20px;
  font-family: Nebulica;
  font-size: clamp(40px, 7vw, 70px);
  font-style: normal;
  font-weight: 300;
  line-height: 95%;
  letter-spacing: -0.7px;
  text-transform: uppercase;
  display: inline-block;
}

.hero h1 .span1 {
  color: #9178ff;
  margin-bottom: 20px;
  font-family: Nebulica;
  font-size: clamp(40px, 7vw, 70px);
  font-style: normal;
  font-weight: 300;
  line-height: 95%;
  letter-spacing: -0.7px;
  text-transform: uppercase;
  display: inline-block;
}

.hero p {
  color: #f2f2f2;
  text-align: center;
  font-family: "Darker Grotesque";
  font-size: 25px;
  max-width: 650px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 25px;
}

/* Services Section */
.services {
  padding-top: 20vh;
  min-height: 100vh;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  background: linear-gradient(180deg, rgba(21, 12, 60, 0) 0%, #150c3c 57.1%);
}

.services .container {
  display: flex;
  gap: 60px;
  width: 100%;
}

.services .sub-container {
  flex: 1 1 500px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.services h2 {
  color: #f2f2f2;
  font-family: Nebulica;
  font-size: 45px;
  font-style: normal;
  font-weight: 300;
  line-height: 105%;
  text-transform: uppercase;
}

.services .services-heading {
  color: #f2f2f2;
  font-family: Nebulica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  letter-spacing: 1.356px;
  text-transform: uppercase;
}

.service-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27px 0; /* (86px - 32px)/2 to center the text vertically */
  width: 100%;
}

.service-description {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 20px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  transform: translateY(-10px);
}

.services .service-description p {
  margin-top: 0px;
}

.service-card.expanded {
  height: auto;
}

.service-card.expanded .service-description {
  max-height: 1000px;
  margin-bottom: 20px;
  opacity: 1;
  transform: translateY(0);
}

.service-card.expanded .vertical-divider {
  transform: rotate(90deg);
  opacity: 0;
}

.service-card.expanded .border {
  transform: rotate(180deg);
}

.services p {
  color: #f2f2f2;
  font-family: "Darker Grotesque";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 550;
  margin-top: 25px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.service-card {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #9178ff;
  min-height: 86px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.service-card h3 {
  color: #f2f2f2;
  font-family: Nebulica;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 32.95px;
}

.service-description ul {
  color: #f2f2f2;
  font-family: "Darker Grotesque";
  font-size: 25px;
  font-weight: 400;
  line-height: 125%;
  margin-top: 10px;
  padding-left: 40px;
}

.border {
  border-radius: 30px;
  border: 2px solid #f2f2f2;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
}

@media (max-width: 1024px) {
  .services .container {
    flex-direction: column;
    gap: 40px;
  }

  .services .sub-container {
    flex: 1 1 auto;
    width: 100%;
  }

  .services h2 {
    font-size: clamp(32px, 5vw, 45px);
  }

  .services p {
    font-size: clamp(18px, 3vw, 25px);
  }
}

@media (max-width: 480px) {
  .services {
    padding-top: 10vh;
  }

  .services .container {
    gap: 30px;
  }

  .service-header {
    padding: 20px 0;
  }
}

.border:hover {
  transform: scale(1.1);
}

.horizontal-divider {
  background: #f2f2f2;
  width: 14px;
  height: 2px;
  flex-shrink: 0;
  position: absolute;
  transition: transform 0.3s ease;
}

.vertical-divider {
  width: 2px;
  height: 14px;
  flex-shrink: 0;
  background: #f2f2f2;
  position: absolute;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Latest Insights */
.insights {
  padding: 80px 0;
  background: #f8f9fa;
}

.insights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.insight-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.insight-content {
  padding: 20px;
}

/* Story Section */
.story {
  background: linear-gradient(180deg, rgba(145, 120, 255, 0) 0%, #9178ff 100%);
  padding: clamp(40px, 5vh, 80px) 0;
}

.story-content {
  display: flex;
  gap: 60px;
  align-items: flex-start;
  margin: 0 auto;
}

.story-heading {
  flex: 1;
  text-align: left;
  color: #f2f2f2;
  font-family: Nebulica;
  font-size: clamp(32px, 5vw, 45px);
  font-style: normal;
  font-weight: 300;
  line-height: 105%;
}

.story-heading h3 {
  text-transform: uppercase;
}

.story-text {
  flex: 1;
  text-align: left;
  color: #f2f2f2;
  font-family: "Darker Grotesque";
  font-size: clamp(18px, 3vw, 25px);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 25px;
  max-width: 1000px;
}

.story-images {
  display: flex;
  gap: 30px;
  margin-top: 40px;
}

.story-images img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.story-images picture {
  width: 40%; /* Roughly matches the smaller image proportion */
  display: flex;
}

.story-images picture:last-child {
  width: 60%; /* Roughly matches the larger image proportion */
}

@media (max-width: 768px) {
  .story-content {
    flex-direction: column;
    gap: 20px;
    padding: 0 15px;
  }

  .story-heading,
  .story-text {
    text-align: center;
    margin-top: 15px;
  }
}

/* Contact Section */
.contact {
  padding: clamp(40px, 5vh, 80px) 0;
  background: linear-gradient(180deg, #c2b4ff 0%, #d6cdff 100%);
  text-align: center;
  color: #150c3c;
  text-align: center;
  font-family: Nebulica;
  font-size: clamp(18px, 3vw, 25px);
  font-style: normal;
  font-weight: 300;
  line-height: 1.6;
}

.contact-slogan {
  margin-top: clamp(15px, 3vh, 25px);
  margin-bottom: clamp(30px, 5vh, 60px);
}

.contact-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: clamp(30px, 5vw, 60px);
}

.contact h3 {
  font-size: clamp(32px, 5vw, 45px);
  font-family: Nebulica;
}

.contact-info {
  text-align: left;
  flex: 1;
  font-family: Darker Grotesque;
  font-weight: 400;
}

.contact-image {
  flex: 2;
}

.contact-image img {
  width: auto;
  height: auto;
  max-height: 500px;
  max-width: 100%;
  display: block;
}

@media (max-width: 768px) {
  .contact {
    padding: 60px 0;
  }

  .contact h3 {
    font-size: 36px;
  }

  .contact-wrapper {
    flex-direction: column;
    gap: 30px;
    padding: 0 15px;
  }

  .contact-image {
    max-width: 100%;
    order: -1;
  }

  .contact-image img {
    max-height: 400px;
  }

  .contact-info {
    width: 100%;
    text-align: center;
  }

  .contact-image img {
    margin: 0 auto;
  }
}

/* Footer */
footer {
  background: linear-gradient(180deg, rgba(21, 12, 60, 0) 0%, #150c3c 100%);
  color: #fff;
  padding: 60px 0;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
}

.footer-links h3 {
  font-family: Nebulica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.footer-links a {
  color: #9178ff;
  font-family: Nebulica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 6px 11px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #9178ff;
  text-decoration: none;
}

.footer-contact-info p {
  color: #f2f2f2;
  font-family: "Darker Grotesque";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.address {
  margin-top: 10px;
}

.copyright {
  color: #fff;
  font-family: Nebulica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  padding-top: 10vh;
}

.social-links {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.button {
  display: inline-block;
  padding: 12px 30px;
  background: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hero h1 {
    font-size: 36px;
  }
}

.event-wrapper {
  width: 100%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/event-banner.png");
  background-size: contain;
  background-position: center;
  background-repeat: repeat;
}

.event-banner {
  height: 160px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  padding-top: 10px;
}

.event-info {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-info h1 {
  font-family: "Neuething Sans";
  color: #eeeaff;
  font-size: 42px;
  font-weight: 700;
  font-style: normal;
  line-height: 88%;
}

.event-info .host {
  font-family: Nebulica;
  font-size: 18px;
  font-weight: 400;
  /* line-height: 82%; */
}

.event-info p {
  font-family: "Neuething Sans";
  font-style: normal;
  font-weight: 400;
  /* line-height: 90%; */
  color: #f2f2f2;
}

.info-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.event-cta {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-logos {
  margin-top: -15px;
  display: flex;
  align-items: center;
}

.join-event {
  border-radius: 35px;
  background: #bdfff1;
  padding: 10px 60px;
}

@media (max-width: 768px) {
  .event-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .event-logos {
    margin-right: -6px;
  }
}
