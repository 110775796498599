@font-face {
  font-family: Darker Grotesque;
  src: url("DarkerGrotesque-VariableFont_wght.505afbfd.woff2") format("woff2-variations");
  font-weight: 100 900;
  font-display: swap;
  size-adjust: 100%;
}

@font-face {
  font-family: Nebulica;
  src: url("Nebulica-Regular.9e57c0cf.woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Nebulica;
  src: url("Nebulica-Medium.aeb827b8.woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: Nebulica;
  src: url("Nebulica-SemiBold.79862e1f.woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Nebulica;
  src: url("Nebulica-Bold.42398ebe.woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Neuething Sans;
  src: url("NeuethingSans-Regular.1f9db400.woff2");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: Neuething Sans;
  src: url("NeuethingSans-Bold.160ebe7d.woff2");
  font-display: swap;
  font-weight: 700;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: #333;
  background: linear-gradient(0deg, #f2f2f266 0% 100%), #f2f2f2;
  padding-top: 80px;
  line-height: 1.6;
}

.container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 40px;
}

.video-background {
  z-index: -2;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.video-background video {
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-background .overlay {
  z-index: 2;
  background: #00000080;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-background.image-fallback {
  background-image: url("poster-image.505d3e00.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

header {
  z-index: 1000;
  background: #00000026;
  width: 100%;
  height: 80px;
  padding: 20px 0;
  position: fixed;
  top: 0;
}

nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.nav-links {
  align-items: flex-start;
  gap: 35px;
  display: inline-flex;
  position: relative;
}

.nav-links a {
  color: #f2f2f2;
  text-align: center;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Darker Grotesque-Medium, Helvetica;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  position: relative;
}

.hero {
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 80vh;
  display: flex;
  position: relative;
}

.hero-overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.grid-02 {
  mix-blend-mode: screen;
  z-index: 2;
  pointer-events: none;
  object-fit: cover;
  width: 100%;
  height: 110vh;
  position: absolute;
  top: 0;
  left: 0;
}

.scroll-button {
  cursor: pointer;
  z-index: 3;
  background: none;
  border: none;
}

.hero .container {
  z-index: 3;
  margin-top: auto;
  margin-bottom: auto;
}

.hero h1 .span0 {
  color: #f2f2f2;
  letter-spacing: -.7px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: Nebulica;
  font-size: clamp(40px, 7vw, 70px);
  font-style: normal;
  font-weight: 300;
  line-height: 95%;
  display: inline-block;
}

.hero h1 .span1 {
  color: #9178ff;
  letter-spacing: -.7px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-family: Nebulica;
  font-size: clamp(40px, 7vw, 70px);
  font-style: normal;
  font-weight: 300;
  line-height: 95%;
  display: inline-block;
}

.hero p {
  color: #f2f2f2;
  text-align: center;
  max-width: 650px;
  margin-top: 25px;
  font-family: Darker Grotesque;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.services {
  background: linear-gradient(#150c3c00 0%, #150c3c 57.1%);
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 100vh;
  padding-top: 20vh;
  display: flex;
}

.services .container {
  gap: 60px;
  width: 100%;
  display: flex;
}

.services .sub-container {
  flex-direction: column;
  flex: 500px;
  align-items: flex-start;
  display: inline-flex;
}

.services h2 {
  color: #f2f2f2;
  text-transform: uppercase;
  font-family: Nebulica;
  font-size: 45px;
  font-style: normal;
  font-weight: 300;
  line-height: 105%;
}

.services .services-heading {
  color: #f2f2f2;
  letter-spacing: 1.356px;
  text-transform: uppercase;
  font-family: Nebulica;
  font-size: 45px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
}

.service-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 27px 0;
  display: flex;
}

.service-description {
  opacity: 0;
  max-height: 0;
  padding: 0 20px;
  transition: all .3s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
  transform: translateY(-10px);
}

.services .service-description p {
  margin-top: 0;
}

.service-card.expanded {
  height: auto;
}

.service-card.expanded .service-description {
  opacity: 1;
  max-height: 1000px;
  margin-bottom: 20px;
  transform: translateY(0);
}

.service-card.expanded .vertical-divider {
  opacity: 0;
  transform: rotate(90deg);
}

.service-card.expanded .border {
  transform: rotate(180deg);
}

.services p {
  color: #f2f2f2;
  max-width: 550px;
  margin-top: 25px;
  font-family: Darker Grotesque;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.services-grid {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
  display: grid;
}

.service-card {
  cursor: pointer;
  border-bottom: 2px solid #9178ff;
  flex-direction: column;
  width: 100%;
  min-height: 86px;
  transition: all .3s cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.service-card h3 {
  color: #f2f2f2;
  font-family: Nebulica;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 32.95px;
}

.service-description ul {
  color: #f2f2f2;
  margin-top: 10px;
  padding-left: 40px;
  font-family: Darker Grotesque;
  font-size: 25px;
  font-weight: 400;
  line-height: 125%;
}

.border {
  cursor: pointer;
  border: 2px solid #f2f2f2;
  border-radius: 30px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  transition: transform .3s;
  display: flex;
  position: relative;
}

@media (width <= 1024px) {
  .services .container {
    flex-direction: column;
    gap: 40px;
  }

  .services .sub-container {
    flex: auto;
    width: 100%;
  }

  .services h2 {
    font-size: clamp(32px, 5vw, 45px);
  }

  .services p {
    font-size: clamp(18px, 3vw, 25px);
  }
}

@media (width <= 480px) {
  .services {
    padding-top: 10vh;
  }

  .services .container {
    gap: 30px;
  }

  .service-header {
    padding: 20px 0;
  }
}

.border:hover {
  transform: scale(1.1);
}

.horizontal-divider {
  background: #f2f2f2;
  flex-shrink: 0;
  width: 14px;
  height: 2px;
  transition: transform .3s;
  position: absolute;
}

.vertical-divider {
  background: #f2f2f2;
  flex-shrink: 0;
  width: 2px;
  height: 14px;
  transition: transform .3s, opacity .3s;
  position: absolute;
}

.insights {
  background: #f8f9fa;
  padding: 80px 0;
}

.insights-grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
  display: grid;
}

.insight-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px #0000001a;
}

.insight-content {
  padding: 20px;
}

.story {
  background: linear-gradient(#9178ff00 0%, #9178ff 100%);
  padding: clamp(40px, 5vh, 80px) 0;
}

.story-content {
  align-items: flex-start;
  gap: 60px;
  margin: 0 auto;
  display: flex;
}

.story-heading {
  text-align: left;
  color: #f2f2f2;
  flex: 1;
  font-family: Nebulica;
  font-size: clamp(32px, 5vw, 45px);
  font-style: normal;
  font-weight: 300;
  line-height: 105%;
}

.story-heading h3 {
  text-transform: uppercase;
}

.story-text {
  text-align: left;
  color: #f2f2f2;
  flex: 1;
  max-width: 1000px;
  margin-top: 25px;
  font-family: Darker Grotesque;
  font-size: clamp(18px, 3vw, 25px);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.story-images {
  gap: 30px;
  margin-top: 40px;
  display: flex;
}

.story-images img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.story-images picture {
  width: 40%;
  display: flex;
}

.story-images picture:last-child {
  width: 60%;
}

@media (width <= 768px) {
  .story-content {
    flex-direction: column;
    gap: 20px;
    padding: 0 15px;
  }

  .story-heading, .story-text {
    text-align: center;
    margin-top: 15px;
  }
}

.contact {
  text-align: center;
  color: #150c3c;
  text-align: center;
  background: linear-gradient(#c2b4ff 0%, #d6cdff 100%);
  padding: clamp(40px, 5vh, 80px) 0;
  font-family: Nebulica;
  font-size: clamp(18px, 3vw, 25px);
  font-style: normal;
  font-weight: 300;
  line-height: 1.6;
}

.contact-slogan {
  margin-top: clamp(15px, 3vh, 25px);
  margin-bottom: clamp(30px, 5vh, 60px);
}

.contact-wrapper {
  justify-content: space-between;
  align-items: center;
  gap: clamp(30px, 5vw, 60px);
  display: flex;
}

.contact h3 {
  font-family: Nebulica;
  font-size: clamp(32px, 5vw, 45px);
}

.contact-info {
  text-align: left;
  flex: 1;
  font-family: Darker Grotesque;
  font-weight: 400;
}

.contact-image {
  flex: 2;
}

.contact-image img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 500px;
  display: block;
}

@media (width <= 768px) {
  .contact {
    padding: 60px 0;
  }

  .contact h3 {
    font-size: 36px;
  }

  .contact-wrapper {
    flex-direction: column;
    gap: 30px;
    padding: 0 15px;
  }

  .contact-image {
    order: -1;
    max-width: 100%;
  }

  .contact-image img {
    max-height: 400px;
  }

  .contact-info {
    text-align: center;
    width: 100%;
  }

  .contact-image img {
    margin: 0 auto;
  }
}

footer {
  color: #fff;
  background: linear-gradient(#150c3c00 0%, #150c3c 100%);
  padding: 60px 0;
}

.footer-grid {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  display: grid;
}

.footer-links h3 {
  font-family: Nebulica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.footer-links a {
  color: #9178ff;
  border: 1px solid #9178ff;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding: 6px 11px;
  font-family: Nebulica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  display: flex;
}

.footer-contact-info p {
  color: #f2f2f2;
  font-family: Darker Grotesque;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.address {
  margin-top: 10px;
}

.copyright {
  color: #fff;
  opacity: .8;
  padding-top: 10vh;
  font-family: Nebulica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.social-links {
  gap: 20px;
  margin-top: 20px;
  display: flex;
}

.button {
  color: #fff;
  background: #007bff;
  border-radius: 5px;
  margin-top: 20px;
  padding: 12px 30px;
  text-decoration: none;
  display: inline-block;
}

@media (width <= 768px) {
  .nav-links {
    display: none;
  }

  .hero h1 {
    font-size: 36px;
  }
}

.event-wrapper {
  background: #000 url("event-banner.9d2d539d.png") center / contain;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.event-banner {
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  height: 160px;
  padding-top: 10px;
  display: flex;
}

.event-info {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.event-info h1 {
  color: #eeeaff;
  font-family: Neuething Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 88%;
}

.event-info .host {
  font-family: Nebulica;
  font-size: 18px;
  font-weight: 400;
}

.event-info p {
  color: #f2f2f2;
  font-family: Neuething Sans;
  font-style: normal;
  font-weight: 400;
}

.info-wrapper {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.event-cta {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.event-logos {
  align-items: center;
  margin-top: -15px;
  display: flex;
}

.join-event {
  background: #bdfff1;
  border-radius: 35px;
  padding: 10px 60px;
}

@media (width <= 768px) {
  .event-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .event-logos {
    margin-right: -6px;
  }
}
/*# sourceMappingURL=index.1a7e4819.css.map */
